import React, { useEffect, useState } from "react";
import Content from "../components/Content";
import { DeleteCustomer, getCustomers } from "../features/customers/CustomerService";
import Loader from "../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CustomerEdit from "./CustomerEdit";
import DeleteModel from "../components/DeleteModel";
import TableContainer from "../components/TableContainer/TableContainer";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Pagination from "../components/Pagination/Pagination";
import UserAvatar from "../components/UserAvatar/UserAvatar";
import { paginateData } from "../utils/utils";
// import DeleteModal from "../components/CustomModal";

const Customers = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("")
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [allData2, setAllData2] = useState([])
  const [noData, setNoData] = useState(false)
  const [search, setSearch] = useState('')



  const fetchData = async () => {
    setIsLoading(true);
    const response = await getCustomers();
    setAllData(response?.reverse());
    setAllData2(response?.reverse());
    let slicedData = paginateData(page, rowsPerPage, response?.reverse())
    setData(slicedData)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);



  async function deleteCustomer() {
    try {
      await DeleteCustomer(deleteId);
    
    } catch (err) {
      console.error(err);
    }
    setOpenModal1(false)
    fetchData()
  }

  useEffect(() => {
    let slicedData = paginateData(page, rowsPerPage, allData)
    setData(slicedData)
  }, [page, rowsPerPage])



  function searchData(e) {
    let value = e?.toLowerCase()
    setSearch(e)
    let arr = [...allData2]
    if (e == '') {
      setAllData(allData2)
      let slicedData = paginateData(page, rowsPerPage, allData2)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    } else {
      let newArr = arr?.filter(res => res?.firstname?.toLowerCase()?.includes(value))
      setAllData(newArr)
      let slicedData = paginateData(page, rowsPerPage, newArr)
      setData(slicedData)
      if (slicedData.length == 0) {
        setNoData(true)
      } else {
        setNoData(false)
      }
    }

  }
  

  
  return (
    <Content>
      {isLoading ? <Loader open={isLoading} /> : ""}
      <div>
        <h2 className="">Customers</h2>
        <div className="row justify-content-md-between justify-content-sm-between justify-content-md-start mt-3 mb-4">
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <input
              type="search"
              className="form-control w-100"
              placeholder="Search customer by name"
              value={search}
              onChange={(e) => {
                if (e.target.value == " ") {
                  e.target.value = "";
                } else {
                  searchData(e.target.value);
                }
              }}
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex justify-content-end mt-md-0 mt-sm-0 mt-2"></div>
        </div>
        <TableContainer>
          <Table>
            {noData ? <caption className='text-center'>No Data Found</caption> : ''}
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '2%' }}></TableCell>
                <TableCell className="fw-bold">Name</TableCell>
                <TableCell className="fw-bold">Phone Number</TableCell>
                <TableCell className="fw-bold">Email</TableCell>
                <TableCell className="fw-bold">Age range</TableCell>
                <TableCell className="fw-bold">Address</TableCell>
                <TableCell className="fw-bold" style={{width:'2%'}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((res, index) => {

                return (
                  <TableRow hover key={index} >
                    <TableCell><UserAvatar name={res?.firstname || '--'} img={res?.profile?.fileUrl} /></TableCell>
                    <TableCell className='text-capitalize'>{res?.firstname || '--'}</TableCell>
                    <TableCell className='text-capitalize'>{res?.mobile}</TableCell>
                    <TableCell className=''>{res?.email || '--'}</TableCell>
                    <TableCell className='text-capitalize'>{res?.age_range || '--'}</TableCell>
                    <TableCell className='text-capitalize'>{res?.city}, {res?.state}, {res?.country}</TableCell>
                    <TableCell className='text-capitalize'></TableCell>

                  </TableRow>
                )
              })}
            </TableBody>

          </Table>
          <Pagination

            allData={allData}
            currentPage={page}
            paginate={(d) => {
              setPage(d);
            }}
            itemPerPage={rowsPerPage}
          />

        </TableContainer>
        {/* <Table columns={columns} dataSource={data} /> */}
        <CustomerEdit
          open={openModal}
          onClose={() => setOpenModal(false)}
          info={info}
          fetchData={fetchData}
        />
        <DeleteModel
          open={openModal1}
          title={`Are you sure you want to delete this Customer?`}
          hideModal={() => setOpenModal1(false)}
          performAction={deleteCustomer} // Pass the delete function
        />
      </div>
    </Content>
  );
};

export default Customers;
